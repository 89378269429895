<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {minLength, required, email, numeric, maxLength} from "vuelidate/lib/validators";
import Master from "@/apis/Master";
import Swal from "sweetalert2";
import Vue from "vue";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "USER MANAGEMENT",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "USER MANAGEMENT",
          active: true
        }
      ],
      userRoleData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      selected:"true",
      fields: [
        { key: "slNo", sortable: false, label: "SL NO", thStyle: { color: "black", "font-size":"16px"}  },
        { key: "name", sortable: true, label: "NAME",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "mobile", sortable: true, label: "MOBILE NO",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "email", sortable: true, label: "EMAIL ID",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "roleName", sortable: true, label: "ROLE",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "status", sortable: true, label: "STATUS",  thStyle: { color: "black", "font-size":"16px"}  },
        { key: "action", label: "ACTION",  thStyle: { color: "black", "font-size":"16px"} }
      ],
      userRole: {
        fname: "",
        lname: "",
        mobile: "",
        email: "",
        password: "",
        role: "",
        status: "1"
      },
      editUserRole:{
        id: "",
        fname: "",
        lname: "",
        mobile: "",
        email: "",
        password: "",
        role: "",
        status: ""
      },
      submitted: false,
      editSubmitted: false,
      showmodal: false,
      editData: [],
      rolesData: [],
      successMessage: "",
      errorMessage: "",
      errorCodeMessage: "",
      editshowmodal: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.userRoleData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.userRoleData.length;

    Master.adminRoleList().then((res) => {
      console.log(res)
      this.rolesData = res.data.data;
    })

    Master.adminUserList({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.userRoleData = res.data.data;
    })
  },
  validations: {
    userRole: {
      fname: { required, minLength: minLength(3) },
      lname: { required, minLength: minLength(3) },
      email: { required , email},
      mobile: { required , numeric},
      password: { required, maxLength: maxLength(18) },
      role: { required },
      status: { required }
    },
    editUserRole:{
      fname: { required, minLength: minLength(3) },
      lname: { required, minLength: minLength(3) },
      email: { required , email},
      mobile: { required , numeric},
      role: { required },
      status: { required }
    }
  },
  methods: {
    /**
     * Edit Country Modal
     */
    editModal(data){
      this.editshowmodal = true
      Master.adminUserDetails({
        userId:data
      }).then((res) => {
        console.log(res)
        this.editUserRole.id = res.data.data.userId
        this.editUserRole.fname = res.data.data.firstName
        this.editUserRole.lname = res.data.data.lastName
        this.editUserRole.email = res.data.data.email
        this.editUserRole.mobile = res.data.data.mobile
        this.editUserRole.role = res.data.data.userRole
        this.editUserRole.status = res.data.data.status

      })
    },
    async deleteUser(data){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.adminUserDelete(
              {
                trash: 0,
                userId: data
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
            Master.adminUserList({
              trash: 0,
            }).then((res) => {
              console.log(res)
              this.userRoleData = res.data.data;
            })
          })
        }
      });
    },
    async handleSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.userRole.$invalid) {
        return;
      } else {
        Master.adminUserAdd(
            {
              firstName : this.userRole.fname,
              lastName : this.userRole.lname,
              email : this.userRole.email,
              mobile : this.userRole.mobile,
              password : this.userRole.password,
              userRole : this.userRole.role,
              status : this.userRole.status
            }).then((res) => {
          console.log(res)
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.adminUserList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.userRoleData = res.data.data;
          })
          this.showmodal = false;
        }).catch((err) => {

          this.$bvToast.toast(
              "" + err.response.data.errors + "",
              {
                title: `Error Message`,
                variant: 'danger',
                solid: true
              })
          this.$bvToast.toast(
              "" + err.response.data.error.code[0] + "",
              {
                title: `Error Message`,
                variant: 'danger',
                solid: true
              })
          this.showmodal = true;

        })
      }
      this.submitted = false;
      this.userRole.status = 1;
      this.userRole.fname = "";
      this.userRole.lname = "";
      this.userRole.mobile = "";
      this.userRole.email = "";
      this.userRole.role = "";
      this.userRole.password = "";
    },
    handleUpdate(){
      this.editSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.editUserRole.$invalid) {
        return;
      } else {
        Master.adminUserUpdate(
            {
              userId: this.editUserRole.id,
              firstName : this.editUserRole.fname,
              lastName : this.editUserRole.lname,
              email : this.editUserRole.email,
              mobile : this.editUserRole.mobile,
              userRole : this.editUserRole.role,
              status : this.editUserRole.status
            }).then((res) => {
          console.log(res)
          // this.successMessage = res.data.message;
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          Master.adminUserList({
            trash: 0
          }).then((res) => {
            console.log(res)
            this.userRoleData = res.data.data;
          })
          this.editshowmodal = false;
        }).catch((err) => {
          console.log(err)
          this.$bvToast.toast(""+err.response.data.errors+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          // this.errorMessage = err.response.data.errors;
          this.editshowmodal = true;
        })
      }
      this.editSubmitted = false;
    },
    hideModal() {
      this.submitted = false;
      this.showmodal = false;
      this.userRole.status = 1;
      this.userRole.fname = "";
      this.userRole.lname = "";
      this.userRole.mobile = "";
      this.userRole.email = "";
      this.userRole.role = "";
    },
    hideEditModal() {
      this.editSubmitted = false;
      this.editshowmodal = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform: uppercase !important;">
            <div class="btn-toolbar">
              <a href="javascript:void(0);" class="btn btn-primary mb-2 mr-1"  @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Add
              </a>
            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="userRoleData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left
                >
                  <template v-slot:cell(slNo)="data">
                    <p class="badge font-size-12">{{data.index+1}}</p>
                  </template>
                  <template v-slot:cell(mobile)="row">
                    <p class="badge font-size-12">
                      {{row.item.mobile}}
                    </p>
                  </template>
                  <template v-slot:cell(email)="row">
                    <p class="badge font-size-12">
                      {{row.item.email}}
                    </p>
                  </template>
                  <template v-slot:cell(roleName)="row">
                    <p class="badge font-size-12">
                      {{row.item.roleName}}
                    </p>
                  </template>
                  <template v-slot:cell(status)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 badge-soft-success"

                    >Active</div>
                    <div v-if="row.value == 0"
                         class="badge font-size-12 badge-soft-danger"
                    >In-Active</div>
                  </template>
                  <template v-slot:cell(name)="data">
                    <p class="badge font-size-12">{{data.item.firstName}} {{data.item.lastName}}</p>
                  </template>

                  <template v-slot:cell(action) = "data">
                    <a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        @click="editModal(data.item.userId)"
                        v-b-tooltip.hover
                        title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a>
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="deleteUser(data.item.userId)"
                        v-b-tooltip.hover
                        title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Add -->
    <b-modal
        id="modal-1"
        v-model="showmodal"
        title="ADD USER"
        title-class="text-dark font-18"
        hide-footer
        @close="hideModal"
    >
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="name">FIRST NAME</label>
              <input
                  id="name"
                  v-model="userRole.fname"
                  type="text"
                  class="form-control"
                  placeholder="ENTER NAME"
                  :class="{ 'is-invalid': submitted && $v.userRole.fname.$error }" style="text-transform: uppercase !important;"
              />
              <div v-if="submitted && $v.userRole.fname.$error" class="invalid-feedback">
                <span v-if="!$v.userRole.fname.required">FIRST NAME IS REQUIRED</span>
                <span v-if="!$v.userRole.fname.minLength">FIRST NAME SHOULD BE ATLEAST 3 CHARACTER</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="code">LAST NAME</label>
              <input
                  id="code"
                  v-model="userRole.lname"
                  type="text"
                  class="form-control"
                  placeholder="ENTER LAST NAME"
                  :class="{ 'is-invalid': submitted && $v.userRole.lname.$error }" style="text-transform: uppercase !important;"
              />
              <div v-if="submitted && $v.userRole.lname.$error" class="invalid-feedback">
                <span v-if="!$v.userRole.lname.required">LAST NAME IS REQUIRED</span>
                <span v-if="!$v.userRole.lname.minLength">LAST NAME SHOULD BE ATLEAST 3 CHARACTER</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="mobile">MOBILE NO</label>
              <input
                  id="mobile"
                  v-model="userRole.mobile"
                  type="text"
                  class="form-control"
                  placeholder="ENTER MOBILE NO"
                  :class="{ 'is-invalid': submitted && $v.userRole.mobile.$error }" style="text-transform: uppercase !important;"
              />
              <div v-if="submitted && $v.userRole.mobile.$error" class="invalid-feedback">
                <span v-if="!$v.userRole.mobile.required">MOBILE NUMBER IS REQUIRED</span>
                <span v-if="!$v.userRole.mobile.numeric">MOBILE NUMBER MUST BE IN NUMBER</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="email">EMAIL ID</label>
              <input
                  id="email"
                  v-model="userRole.email"
                  type="text"
                  class="form-control"
                  placeholder="ENTER EMAIL ID"
                  :class="{ 'is-invalid': submitted && $v.userRole.email.$error }" style="text-transform: uppercase !important;"
              />
              <div v-if="submitted && $v.userRole.email.$error" class="invalid-feedback">
                <span v-if="!$v.userRole.email.required">EMAIL ID IS REQUIRED</span>
                <span v-if="!$v.userRole.email.email">EMAIL ID IS INVALID</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="password">PASSWORD</label>
              <input
                  id="password"
                  v-model="userRole.password"
                  type="password"
                  class="form-control"
                  placeholder="ENTER PASSWORD"
                  :class="{ 'is-invalid': submitted && $v.userRole.password.$error }" style="text-transform: uppercase !important;"
              />
              <div v-if="submitted && $v.userRole.password.$error" class="invalid-feedback">
                <span v-if="!$v.userRole.password.required">PASSWORD IS REQUIRED</span>
                <span v-if="!$v.userRole.password.maxLength">PASSWORD MUST NOT BE GREATER THAN 18 CHARACTER</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="role">ROLE</label>
              <select class="form-control"
                      id="role"
                      v-model="userRole.role"
                      :class="{ 'is-invalid': submitted && $v.userRole.role.$error }" style="text-transform: uppercase !important;">
                <option value="">SELECT ROLE</option>
                <option v-for="role in rolesData" :value="role.roleId" :key="role.roleId">{{role.roleName}}</option>
              </select>
              <div
                  v-if="submitted && !$v.userRole.role.required"
                  class="invalid-feedback"
              >ROLE IS REQUIRED</div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="position">STATUS</label>
              <select class="form-control"
                      id="position"
                      v-model="userRole.status"
                      :class="{ 'is-invalid': submitted && $v.userRole.status.$error }">
                <option value="1">ACTIVE</option>
                <option value="0">IN-ACTIVE</option>
              </select>
              <div
                  v-if="submitted && !$v.userRole.status.required"
                  class="invalid-feedback"
              >STATUS IS REQUIRED</div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->


    <!-- Modal Edit -->
    <b-modal
        id="modal-1"
        v-model="editshowmodal"
        title="EDIT USER"
        title-class="text-dark font-18"
        hide-footer
        @close="hideEditModal"
    >
      <form @submit.prevent="handleUpdate">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="edit_first">FIRST NAME</label>
              <input
                  id="edit_first"
                  v-model="editUserRole.fname"
                  type="text"
                  class="form-control"
                  placeholder="ENTER NAME"
                  :class="{ 'is-invalid': editSubmitted && $v.editUserRole.fname.$error }" style="text-transform: uppercase !important;"
              />
              <div v-if="editSubmitted && $v.editUserRole.fname.$error" class="invalid-feedback">
                <span v-if="!$v.editUserRole.fname.required">FIRST NAME IS REQUIRED</span>
                <span v-if="!$v.editUserRole.fname.minLength">FIRST NAME SHOULD BE ATLEAST 3 CHARACTER</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="edit_last">LAST NAME</label>
              <input
                  id="edit_last"
                  v-model="editUserRole.lname"
                  type="text"
                  class="form-control"
                  placeholder="ENTER LAST NAME"
                  :class="{ 'is-invalid': editSubmitted && $v.editUserRole.lname.$error }" style="text-transform: uppercase !important;"
              />
              <div v-if="editSubmitted && $v.editUserRole.lname.$error" class="invalid-feedback">
                <span v-if="!$v.editUserRole.lname.required">LAST NAME IS REQUIRED</span>
                <span v-if="!$v.editUserRole.lname.minLength">LAST NAME SHOULD BE ATLEAST 3 CHARACTER</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="edit_mobile">MOBILE NO</label>
              <input
                  id="edit_mobile"
                  v-model="editUserRole.mobile"
                  type="text"
                  class="form-control"
                  placeholder="ENTER MOBILE NO"
                  :class="{ 'is-invalid': editSubmitted && $v.editUserRole.mobile.$error }" style="text-transform: uppercase !important;"
              />
              <div v-if="editSubmitted && $v.editUserRole.mobile.$error" class="invalid-feedback">
                <span v-if="!$v.editUserRole.mobile.required">MOBILE NUMBER IS REQUIRED</span>
                <span v-if="!$v.editUserRole.mobile.numeric">MOBILE NUMBER MUST BE IN NUMBER</span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="edit_email">EMAIL ID</label>
              <input
                  id="edit_email"
                  v-model="editUserRole.email"
                  type="text"
                  class="form-control"
                  placeholder="ENTER EMAIL ID"
                  :class="{ 'is-invalid': editSubmitted && $v.editUserRole.email.$error }" style="text-transform: uppercase !important;"
              />
              <div v-if="editSubmitted && $v.editUserRole.email.$error" class="invalid-feedback">
                <span v-if="!$v.editUserRole.email.required">EMAIL ID IS REQUIRED</span>
                <span v-if="!$v.editUserRole.email.email">EMAIL ID IS INVALID</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="edit_role">ROLE</label>
              <select class="form-control"
                      id="edit_role"
                      v-model="editUserRole.role"
                      :class="{ 'is-invalid': editSubmitted && $v.editUserRole.role.$error }">
                <option value="">SELECT ROLE</option>
                <option v-for="role in rolesData" :value="role.roleId" :key="role.roleId">{{role.roleName}}</option>
              </select>
              <div
                  v-if="editSubmitted && !$v.editUserRole.role.required"
                  class="invalid-feedback"
              >ROLE IS REQUIRED</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="edit_status">STATUS</label>
              <select class="form-control"
                      id="edit_status"
                      v-model="editUserRole.status"
                      :class="{ 'is-invalid': editSubmitted && $v.editUserRole.status.$error }">
                <option value="1">ACTIVE</option>
                <option value="0">IN-ACTIVE</option>
              </select>
              <div
                  v-if="editSubmitted && !$v.editUserRole.status.required"
                  class="invalid-feedback"
              >STATUS IS REQUIRED</div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">UPDATE</button>
          <b-button class="ml-1" variant="danger" @click="hideEditModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
<!--     end modal-->
  </Layout>
</template>